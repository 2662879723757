.mem-table-container {
  grid-column-start: 1;
  grid-column-end: -1;
  grid-row-start: 2;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.segment-select-container {
  box-sizing: border-box;
  grid-column-start: 1;
  grid-column-end: -1;
  grid-row-start: 1;
  width: 100%;
  border-top: 2px solid var(--secondDarkBgColor);
}

.segment-select {
  width: 100%;
  height: 100%;
}

.mem-cont {
  grid-column-start: 1;
  grid-column-end: -1;
  grid-row-start: 2;
  width: 100%;
  height: 356.83px;
  overflow-y: scroll;
}

.mem-table {
  width: 100%;
}

.mem-buttons {
  display: flex;
  grid-column-start: 1;
  grid-column-end: -1;
  grid-row-start: 3;
  width: 100%;
}

.mem-button {
  width: 50%;
  background-color: var(--darkBgColor);
  text-align: center;
  color: var(--fontColor);
  font-family: Poppins, sans-serif;
  border: none;
  padding: 10px 0;
  border-top: 2px solid var(--secondDarkBgColor);
}

.mem-button-left {
  border-bottom-left-radius: 5px;
}

.mem-button-right {
  border-bottom-right-radius: 5px;
}

.mem-button:hover {
  cursor: pointer;
  color: var(--yellow);
  border-top: 2px solid var(--red);
}

.unclickable {
  color: var(--secondDarkBgColor);
}

.unclickable:hover {
  color: var(--secondDarkBgColor);
  border-top: 2px solid var(--secondDarkBgColor);
  cursor: not-allowed;
}

.mem-table {
  width: 100%;
  padding: 0px 5px 5px 5px;
  background-color: var(--darkBgColor);
}

.mem-table thead th {
  color: var(--secondDarkBgColor);
  top: 0;
  position: sticky;
  background-color: var(--darkBgColor);
  padding: 10px 10px 10px 0;
  text-align: left;
}

.mem-table tbody tr td.mem {
  color: #777
}

.mem-table tbody td {
  color: var(--fontColor);
  padding-bottom: 1.25px;
  padding-top: 1.25px;
}