  .sub-editor-container {
    flex-grow: 1;
    flex-basis: 0;
    display: flex;
    flex-direction: column;
    padding: .5rem;
    border-radius: .5rem;
  }

  .sub-editor-title {
    background-color: var(--darkBgColor);
    color: var(--fontColor);
    padding: .5rem .5rem .5rem 1rem;
    border-top-right-radius: .5rem;
    border-top-left-radius: .5rem;
    padding-left: 0px;
    text-align: center;
    vertical-align: middle;
    line-height: 30px;
    position: relative;
  }

  .sub-subcontainer {
    height: 50vh;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }


  .delete {
    position: absolute;
    right: 13px;
    top: 13px;
    filter: invert(17%) sepia(68%) saturate(5851%) hue-rotate(340deg) brightness(88%) contrast(96%);
    height: 20px;
  }

  .delete:hover {
    cursor: pointer;
  }

  .confirm {
    position: absolute;
    right: 39px;
    top: 13px;
    filter: invert(92%) sepia(18%) saturate(767%) hue-rotate(28deg) brightness(91%) contrast(101%);
    height: 20px;
  }

  .confirm:hover {
    cursor: pointer;
  }

  div>a:link,
  div>a:visited,
  div>a:hover,
  div>a:active {
    text-decoration: none;
    color: var(--fontColor);
  }