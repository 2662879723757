.simulator {
  display: grid;
  column-gap: 35px;
  grid-template-columns: 2fr 1fr;
  row-gap: 30px;
  margin: 20px 0px;
}

.storage {
  display: grid;
  grid-template-columns: 1fr;
  height: 416px
}

.storage-tabs {
  display: flex;
  grid-column-start: 1;
  grid-column-end: -1;
  width: 100%;
}

.stor-tab:hover {
  cursor: pointer;
  color: var(--yellow);
}

.stor-tab {
  width: 100%;
  background-color: var(--darkBgColor);
  text-align: center;
  color: var(--fontColor);
  font-family: Poppins, sans-serif;
  border: none;
  padding: 10px 0;
  border-bottom: 2px solid var(--lightBgColor);
}

.stor-tab-left {
  border-top-left-radius: 5px;
}

.stor-tab-right {
  border-top-right-radius: 5px;
}

.selected-tab {
  color: var(--yellow);
  border-bottom: 2px solid var(--red);
}

.console-container {
  grid-column-start: 1;
  grid-column-end: -1;
}

.console {
  height: 395.5px;
  width: 100%;
  padding: 12px 20px;
  box-sizing: border-box;
  border: 2px solid #ccc;
  border-radius: 4px;
  background-color: var(--darkBgColor);
  color: var(--fontColor);
  font-size: 16px;
  resize: none;
}

.console-error {
  color: var(--red);
}