#unauthorised-container {
  box-shadow: 0px 5px 15px -6px rgba(0, 0, 0, 0.75);
  border-radius: 20px;
  margin: 10px auto;
  background-color: var(--darkBgColor);
}

#unauthorised-container h1 {
  margin: 10px 0px;
  color: rgb(83, 133, 145);
  letter-spacing: 2.5px;
  font-size: 30px;
  text-align: center;
  text-shadow: 0px 2px 10px rgba(0, 0, 0, 0.25);
}

#unauthorised-container .toph h1 {
  transform: translateY(20px);
  color: var(--fontColor);
  font-size: 25px;
  margin: 10px 10px;
  display: inline-block;
}


#details {
  display: flex;
  font-size: 0.9em;
  padding-top: 0px;
}

#details span {
  color: var(--fontColor);
  display: inline-block;
}

#details span:nth-child(odd) {
  width: 150px;
  border-left: 2px solid var(--fontColor);
  padding-left: 10px;

}

#unauthorised-container li {
  list-style: none;
  margin: 25px auto;
  padding: 0px;
}

#unauthorised-container ul {
  margin: 0px auto;
  padding: 0px;
  padding-bottom: 25px;
}


@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}